
































































































































































































import { Component, Vue } from 'nuxt-property-decorator'
import PageSeoMixin from '~/mixins/PageSeoMixin'
import services from '~/data/services'
import LogoService from '~/components/LogoService.vue'
import Logo from '~/components/Logo.vue'

@Component({
  name: 'ServicesPage',
  components: {
    LogoService,
    Logo
    // VueTyper: !process.client
    //   ? { inheritAttrs: false, render: (h) => h('span', null, [words[0]]) }
    //   : () => import('vue-typer').then(({ VueTyper }) => VueTyper)
  },
  mixins: [PageSeoMixin]
})
export default class extends Vue {
  // words = words
  windowSize = {
    x: 0,
    y: 0
  }

  serviceList = services.filter((s) => s.Key !== 'TemplateService')
  coverTextsList = [
    'Translation Online Ordering Service',
    'Free Q & A Service with Translators Directly',
    'Translation Memory Creation Service',
    'Bilingual File QA Service',
    'Glossary Creation Service',
    'Neural Machine Translation and Consulting Service',
    'Machine Translation Post-Editing Service',
    'Translation API Service in your CMS'
  ]

  mounted() {
    this.onResize()
  }

  beforeCreate() {
    // this.$router.push({ path: '/auth' })
  }

  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight }
  }
}
